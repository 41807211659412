<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="6" lg="6">
        <b-card>
          <div slot="header"><i class="icon-picture"></i> Embed</div>
          <h5 class="tinytitle" v-show="CreateEmbed">Embed builder</h5>
          <h5 class="tinytitle" v-show="EditEmbed">Edit Embed</h5>
          <hr />
          <b-button
            variant="primary"
            v-on:click="createEmbed_func"
            v-bind:disabled="CreateEmbed"
            class="mr-2"
            >Create Embed</b-button
          >
          <b-button
            variant="primary"
            v-on:click="editEmbed_func"
            v-bind:disabled="EditEmbed"
            class="mr-2"
            >Edit Embed</b-button
          >
          <hr class="horizontal" />
          <b-input-group v-if="EditEmbed">
            <b-form-input
              show="true"
              :maxlength="100000"
              v-model="link"
              type="text"
              placeholder="Enter message link"
            ></b-form-input>
            <!-- Attach Right button -->
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="!link"
                @click="post_link()"
                class="mr-2"
                >Edit</b-button
              >
            </b-input-group-append>
          </b-input-group>
          <b-input-group v-if="CreateEmbed">
            <b-form-textarea
              :maxlength="2000"
              rows="3"
              type="text"
              placeholder="Normal text sent with the embed"
              class="col-12"
              v-model="message_content"
            ></b-form-textarea>
          </b-input-group>
          <small v-if="CreateEmbed">{{message_content.length}}/2000</small>
          <hr />

          <div
            v-show="true"
            v-for="n,index in 4"
            :key="index"
          >
            <b-row class="mr-auto" v-if="show_embed(index)">
              <b-col cols="auto" class="mr-auto ml-2 p-2"> </b-col>

              <b-col cols="auto" class="p-2">
                <b-button
                  variant="ghost-danger"
                  v-if="index>0 && show_embed(index-1)"
                  v-show="show_embed(index)"
                  v-on:click="resetEmbed(index)"
                  class="m-2"
                  >Clear</b-button
                >
              </b-col>
              <b-col cols="auto" class="pt-2 pb-2">
                <b-button
                  v-if="index>0 && show_embed(index-1)"
                  variant="primary"
                  v-on:click="Update_show_embed(index)"
                  class="mt-2 mb-2 ml-auto"
                  >{{show_embed(index) == true ? 'Hide' : 'Add embed'}}</b-button
                >
              </b-col>
            </b-row>
            <div v-else>
              <b-button
                v-if="index>0 && show_embed(index-1)"
                variant="secondary"
                block
                v-on:click="Update_show_embed(index)"
                class="mt-2 mb-2 ml-auto"
                >{{show_embed(index) == true ? 'Hide' : 'Add embed '}}</b-button
              >
            </div>

            <b-card
              id="embedbuilder"
              :style="{'border-left-color': get_embed(index).colors.hex}"
              v-show="index == 0 ? 'true' : show_embed(index)"
            >
              <b-button
                variant="primary"
                v-on:click="update_color(index)"
                class="mt-2 mb-2"
                >Toggle color picker</b-button
              >
              <hr />
              <sketch-picker
                v-on-clickaway="hide_colors"
                v-if="get_color_status(index)"
                v-model="get_embed(index).colors"
                @keyup.esc="hide_colors"
              ></sketch-picker>
              <b-row class="form-group">
                <b-col md="3">
                  <b-form-input
                    :maxlength="256"
                    v-model="get_embed(index).icon_url"
                    pattern="https:\/\/.*"
                    type="text"
                    placeholder="Icon url"
                  ></b-form-input>
                </b-col>
                <b-col md="6">
                  <b-form-input
                    :maxlength="256"
                    v-model="get_embed(index).name_header"
                    type="text"
                    placeholder="Name"
                  ></b-form-input>
                </b-col>
                <b-col md="3">
                  <b-form-input
                    :maxlength="256"
                    v-model="get_embed(index).name_url"
                    pattern="https:\/\/.*"
                    type="text"
                    placeholder="Name url"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col md="9">
                  <b-form-input
                    :maxlength="200"
                    v-model="get_embed(index).title"
                    type="text"
                    placeholder="Title"
                  ></b-form-input>
                  <small>{{title_length}}/256</small>
                </b-col>
                <b-col md="3">
                  <b-form-input
                    :maxlength="200"
                    pattern="https:\/\/.*"
                    v-model="get_embed(index).url"
                    type="text"
                    placeholder="Title url"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-textarea
                    :maxlength="2048"
                    rows="3"
                    type="text"
                    placeholder="Description"
                    class="col-12"
                    v-model="get_embed(index).description"
                  ></b-form-textarea>
                  <small>{{description_length}}/2048</small>
                </b-col>
              </b-row>
              <b-row
                v-for="(f, val) in get_embed(index).fields"
                :key="val"
                class="form-group"
              >
                <b-col md="10">
                  <b-form-input
                    :maxlength="256"
                    v-model="get_embed(index).fields[val].name"
                    type="text"
                    :placeholder="'Title for field ' + (val + 1)"
                  ></b-form-input>
                  <small>{{f.name.length}}/256</small>
                </b-col>
                <b-col md="2">
                  <label :for="'checkbox'+val" class="p-2">
                    <b-form-checkbox
                      :id="'checkbox'+val+index"
                      v-model="get_embed(index).fields[val].inline"
                      >Inline</b-form-checkbox
                    >
                  </label>
                </b-col>
                <b-col md="11">
                  <b-form-textarea
                    :maxlength="1024"
                    rows="3"
                    type="text"
                    :placeholder="'Description for field ' + (val + 1)"
                    class="col-12"
                    v-model="get_embed(index).fields[val].value"
                  ></b-form-textarea>
                  <small>{{f.value.length}}/1024</small>
                </b-col>
                <b-col md="1">
                  <b-button
                    variant="ghost-danger"
                    v-b-tooltip.hover
                    title="Remove field"
                    v-on:click="get_embed(index).fields.splice(val, 1)"
                  >
                    <font-awesome-icon icon="times" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                class="form-group"
                v-if="get_embed(index).fields.length < 25"
              >
                <b-col md="12">
                  <b-button
                    size="md"
                    variant="secondary"
                    block
                    v-on:click="get_embed(index).fields.push({name: '', value: '', inline: false})"
                    >Add field</b-button
                  >
                  <small>{{get_embed(index).fields.length}}/25 fields</small>
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col md="6">
                  <b-form-input
                    :maxlength="200"
                    pattern="https:\/\/.*"
                    v-model="get_embed(index).image_url"
                    type="text"
                    placeholder="Image url (big at the bottom)"
                  ></b-form-input>
                </b-col>
                <b-col md="6">
                  <b-form-input
                    :maxlength="200"
                    pattern="https:\/\/.*"
                    v-model="get_embed(index).thumbnail_url"
                    type="text"
                    placeholder="Thumbnail url (small top right)"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col md="4">
                  <b-form-input
                    :maxlength="2048"
                    v-model="get_embed(index).footer_text"
                    type="text"
                    placeholder="Footer"
                  ></b-form-input>
                  <small>{{footer_text_length}}/2048</small>
                </b-col>
                <b-col md="3">
                  <b-form-input
                    :maxlength="200"
                    pattern="https:\/\/.*"
                    v-model="get_embed(index).footer_icon"
                    type="text"
                    placeholder="Footer icon"
                  ></b-form-input>
                </b-col>
                <b-col md>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-calendar"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="get_embed(index).timestamp"
                      :config="config"
                      class="form-control"
                      placeholder="Select date"
                      name="date"
                    ></flat-pickr>
                    <b-input-group-append>
                      <b-button @click="clearDate(index)" variant="danger"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card>
          </div>

          <hr />
          <h5 class="tinytitle" v-show="CreateEmbed">Destination</h5>
          <multiselect
            v-show="CreateEmbed"
            v-model="channel"
            id="discord_channel_select"
            track-by="id"
            label="name"
            placeholder="Please select a channel"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="groupedChannels"
            :allow-empty="false"
            group-values="channels"
            group-label="category"
            :group-select="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color: #72767d" icon="hashtag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon
                    style="color: #72767d"
                    :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                  />
                  {{ props.option.name || props.option.$groupLabel.name }}
                </span>
              </div>
            </template>
          </multiselect>

          <div slot="footer">
            <b-button
              variant="primary"
              :disabled="!channel"
              v-show="CreateEmbed"
              @click="post_embed"
              >Post</b-button
            >
            <b-button
              variant="ghost-danger"
              v-show="CreateEmbed"
              v-on:click="reset_all_Embed"
              class="m-2"
              >Reset</b-button
            >
            <b-button
              variant="primary"
              v-if="this.list && this.is_readonly==false && this.EditEmbed"
              :disabled="this.is_readonly"
              v-on:click="edit_embed(JSON.parse(list)); "
              class="mr-2"
              >Update</b-button
            >
          </div>
        </b-card>
      </b-col>

      <b-col xs="6" lg="6">
        <b-card header="Preview">
          <h4 class="smalltitle">Preview</h4>

          <div class="wrapper">
            <div
              class="card embed"
              v-for="n,index in 4"
              :key="index"
              v-show="index==0?'true':Object.keys(get_embed(index)).length>2"
              :style="{'border-left-color': get_embed(index).colors.hex, 'border-left-width': '4px', 'border-radius': '5px' ,marginBottom: '5px'}"
            >
              <div class="card-block" :style="{ marginBottom: '5px'}">
                <div class="embed-inner">
                  <div class="embed-author">
                    <img
                      class="embed-author-icon"
                      :src="get_embed(index).icon_url"
                      v-if="get_embed(index).icon_url"
                    />
                    <a
                      class="embed-author-name"
                      :href="get_embed(index).name_url"
                      >{{get_embed(index).name_header}}</a
                    >
                  </div>
                  <div class="embed-title">
                    <a
                      :href="get_embed(index).url"
                      style="color: #fff"
                      >{{get_embed(index).title}}</a
                    >
                  </div>
                  <div class="embed-description">
                    <vue-markdown
                      :html="false"
                      :source="get_embed(index).description"
                      >{{get_embed(index).description}}</vue-markdown
                    >
                  </div>
                  <div
                    class="fields"
                    v-if="get_embed(index).fields.length != 0"
                  >
                    <div
                      :class="'field' + (field.inline ? ' inline' : '')"
                      v-for="(field, field_index) in get_embed(index).fields"
                      :key="field_index"
                    >
                      <div class="field-name">
                        <vue-markdown
                          :html="false"
                          :source="field.name"
                        ></vue-markdown>
                      </div>
                      <div class="field-value">
                        <vue-markdown
                          :html="false"
                          :breaks="false"
                          :source="field.value"
                        ></vue-markdown>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  class="embed-thumb"
                  v-if="get_embed(index).thumbnail_url"
                  :src="get_embed(index).thumbnail_url"
                />
              </div>
              <img
                class="embed-img"
                v-if="get_embed(index).image_url"
                :src="get_embed(index).image_url"
              />
              <div class="embed-footer">
                <img
                  class="embed-author-icon"
                  :src="get_embed(index).footer_icon"
                  v-if="get_embed(index).footer_icon"
                />

                <span>{{get_embed(index).footer_text}}</span>
              </div>
            </div>
          </div>

          <b-form-group>
            <b-input-group>
              <b-button
                variant="outline-primary"
                id="copypasted"
                @click="showCopySuccessMsg"
                v-clipboard:copy="JSON.stringify(full_json(0).embed)"
                class="mr-2"
                >Copy to clipboard</b-button
              >

              <b-form-input
                show="true"
                :maxlength="100000"
                v-model="raw_json"
                type="text"
                placeholder="Raw JSON"
              ></b-form-input>
              <!-- Attach Right button -->
              <b-input-group-append>
                <b-button
                  v-on:click="apply_json(JSON.parse(raw_json)); raw_json=''"
                  variant="primary"
                  >Apply</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b>Note:</b> Only json of first embed will be copied/applied
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { Sketch } from "vue-color";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/themes/dark.css";
import { mixin as clickaway } from "vue-clickaway";
import VueMarkdown from "vue-markdown";
import Multiselect from "vue-multiselect";
import EmbedBuilder from "@/views/oc/EmbedBuilder";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

const reducer = (accumulator, currentValue, currentIndex) =>
  accumulator + (currentValue << currentIndex);

Vue.use(VueNotifications, toast_options);
export default {
  name: "Logging",

  components: {
    cSwitch,
    Callout,
    "sketch-picker": Sketch,
    flatPickr,
    VueMarkdown,
    Multiselect,
    EmbedBuilder
  },
  mixins: [clickaway],
  data: function () {
    return {
      colors: { hex: "#202225" },
      show_color_of_first_embed: false,
      show_color_of_second_embed: false,
      show_color_of_third_embed: false,
      show_color_of_fourth_embed: false,
      config: {
        altInput: true,
        enableTime: true,
        altFormat: "F j, Y H:i",
        dateFormat: "Z",
        time_24hr: true,
      },
      message_content: "",

      show_first_embed: true,
      show_second_embed: false,
      show_third_embed: false,
      show_fourth_embed: false,
      channels: [],
      channel: null,
      new_embed: { fields: [], colors: { hex: "#202225" } },
      second_new_embed: { fields: [], colors: { hex: "#202225" } },
      third_new_embed: { fields: [], colors: { hex: "#202225" } },
      fourth_new_embed: { fields: [], colors: { hex: "#202225" } },
      raw_json: "",
      link: "",
      message_link: "",
      list: "",
      is_readonly:true,
      CreateEmbed: true,
      EditEmbed: false,
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Embed sent",
    },
    showEmbedSuccess: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Embed updated successfully",
    },
    showCopySuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Embed copied to clipboard",
    },
    showFailMsg: {
      type: VueNotifications.types.warn,
      title: "Uh oh!",
      message: "You have to select a channel",
    },
    showTimestampError: {
      type: VueNotifications.types.error,
      title: "Uh oh!",
      message: "Make sure to provide at least one field with the date",
    },
    showAuthorFailMsg: {
      type: VueNotifications.types.warn,
      title: "Uh oh!",
      message: "You can only edit embeds of 'Carl-bot'",
    },
    showEmptyEmbedMsg:{
      type: VueNotifications.types.error,
      title: "Uh oh!",
      message: "Cannot send an empty embed",
    },
    showGuildcheckFailMsg: {
      type: VueNotifications.types.error,
      title: "No Access!",
      message: "I do not have access to that message",
    },
    showInternalErrorMsg:{
     type: VueNotifications.types.error,
      title: "Internal Error!",
      message: "An internal error occurred. Please try again later or report in support server",
    },
    showlinkFailMsg:{
    type: VueNotifications.types.error,
      title: "Invalid Link!",
      message: "Enter Correct link",
    },
    missing_perms_msg:{
    type: VueNotifications.types.error,
      title: "Missing Permissions!",
      message: "I do not have enough permissions to send embed in this channel",
    },
    showRateLimitError:{
    type: VueNotifications.types.error,
      title: "You have been rate-limited!",
      message: "Try again after 10s",
    },
    showContainsEmbedFailMsg:
    {
    type: VueNotifications.types.error,
      title: "Error!",
      message: "This Message does not contains embed",
    }
  },

  methods: {
    edit_embed(data)
    {
    for (let index=0;index<4;index++){
      const filterObjects = Object.fromEntries(
        Object.entries(this.full_json(index).embed)
          .filter(([key]) => key !== 'timestamp' && key !== 'colors' && key !='color' && key !== 'fields' && key!='content_scan_version' && key!='type')
      );
      if (this.full_json(index).embed.timestamp && !(Object.values(filterObjects).some(value => value !== undefined && value !== null && value !== ''))) {
        return this.showTimestampError();
      }}
    this.axios.patch("/api/v1/servers/" + this.$route.params.guild_id + "/embedmessage?embedmessage="+this.message_link, {
          embeds: [this.full_json(0).embed,this.full_json(1).embed,this.full_json(2).embed,this.full_json(3).embed],

        }).then(resp=>{
        if(resp.data.invalid_guild)
          {return this.showGuildcheckFailMsg()};
        if(resp.status==200)
        {
        this.showEmbedSuccess();};
        })
        .catch(error => {
              if(error.response.status==429)
                  {
                  this.showRateLimitError();
                  }
          });
    },
    post_link() {

      if (!this.link) {
        return this.showFailMsg();
      }
      this.message_link = this.link;
      this.axios
        .get("/api/v1/servers/" + this.$route.params.guild_id + "/embedmessage?embedmessage="+this.message_link )
        .then(resp=>{
          if(resp.data.invalid_link)
          {return this.showlinkFailMsg()};
          if(resp.data.invalid_guild)
          {return this.showGuildcheckFailMsg()};
          if(resp.data.does_not_contains_embed)
          {return this.showContainsEmbedFailMsg()
          };
          this.list=JSON.stringify(resp.data.embeds[0],resp.data.embeds[1],resp.data.embeds[2],resp.data.embeds[3],null, "\t");
          if(resp.data.readonly)
          {this.is_readonly=false;


          for (let index=0;index<4;index++)
          {
         var data = resp.data.embeds[index]

         var updated_embed_object = {
         ...data,
          fields: data.fields == undefined ? [] : data.fields,
          colors: {
            hex:
              data.color == undefined
                ? "#4f545c"
                : "#" + data.color.toString(16).padStart(6, "0"),
          },

            name_header:
              data.author == undefined ? undefined : data.author.name,
            icon_url:
              data.author == undefined ? undefined : data.author.icon_url,
            name_url: data.author == undefined ? undefined : data.author.url,
            footer_text:
              data.footer == undefined ? undefined : data.footer.text,
            footer_icon:
              data.footer == undefined ? undefined : data.footer.icon_url,
            thumbnail_url:
              data.thumbnail == undefined ? undefined : data.thumbnail.url,
            image_url: data.image == undefined ? undefined : data.image.url,


      }
      if(index==0)
    { this.new_embed = updated_embed_object}
    else if(index==1)
    { this.second_new_embed= updated_embed_object
      if(Object.keys(this.second_new_embed).length>2)
        {this.show_second_embed=true}
    }
    else if(index==2)
    { this.third_new_embed=updated_embed_object
     if(Object.keys(this.third_new_embed).length>2)
      {this.show_third_embed=true}
    }
    else{
    this.fourth_new_embed=updated_embed_object
     if(Object.keys(this.fourth_new_embed).length>2)
        {this.show_fourth_embed=true}
    }
      };
          }
          else{this.is_readonly=true;
          this.showAuthorFailMsg();}
          })
          .catch(error => {
              if(error.response.status==429)
                  {
                  this.showRateLimitError();
                  }
  });
    },

    post_embed() {
      if (!this.channel) {
        return this.showFailMsg();
      }
       for (let index=0;index<4;index++){
      const filterObjects = Object.fromEntries(
        Object.entries(this.get_embed(index))
          .filter(([key]) => key !== 'timestamp' && key !== 'colors' && key !== 'fields')
      );
      const filterFields = this.get_embed(index).fields.map(({ name, value }) => ({ name, value }));
      const fieldValues = filterFields.some(({ name, value }) => {
      return typeof name === 'string' && name.trim() !== '' ||
         typeof value === 'string' && value.trim() !== '';
       });
      if (this.get_embed(index).timestamp && !(Object.values(filterObjects).some(value => value !== undefined && value !== null && value !== '') || fieldValues)) {
        return this.showTimestampError();
      }}
      this.axios
        .post("/api/v1/servers/" + this.$route.params.guild_id + "/embed", {
        embeds: [this.full_json(0).embed,this.full_json(1).embed,this.full_json(2).embed,this.full_json(3).embed],
          channel: this.channel.id,
          content: this.message_content,
        })
        .then(resp => {
          if(resp.data.missing_perms) {return this.missing_perms_msg()};
          if (resp.data.empty_embed) { return this.showEmptyEmbedMsg() };
          if(resp.data.ratelimit_error) {return this.showRateLimitError()};
          if(resp.data.internal_error){return this.showInternalErrorMsg()};
          this.showSuccessMsg()
        }).catch(error => {
              if(error.response.status==429)
                  {
                  this.showRateLimitError();
                  }
          });
    },
    update_color(index)
    {
    if(index==0)
    {this.show_color_of_first_embed=!this.show_color_of_first_embed}
    else if(index==1)
    {this.show_color_of_second_embed=!this.show_color_of_second_embed}
    else if(index==2)
    {this.show_color_of_third_embed=!this.show_color_of_third_embed}
    else{
    this.show_color_of_fourth_embed=!this.show_color_of_fourth_embed
    }
    },
    show_embed(index)
    {
    if(index==0)
    {return this.show_first_embed}
    else if(index==1)
    {return this.show_second_embed}
    else if(index==2)
    {return this.show_third_embed}
    else if(index==3){
    return this.show_fourth_embed
    }
    },
    Update_show_embed(index)
    {
    if(index==1)
    {this.show_second_embed=!this.show_second_embed}
    else if(index==2)
    { this.show_third_embed=!this.show_third_embed}
    else if(index==3){
     this.show_fourth_embed=!this.show_fourth_embed
    }
    },
    get_embed(index){
    if(index==0)
    {return this.new_embed}
    else if(index==1)
    {return this.second_new_embed}
    else if(index==2)
    {return this.third_new_embed}
    else{
    return this.fourth_new_embed
    }},
    get_color_status(index){
    if(index==0)
    {return this.show_color_of_first_embed}
    else if(index==1)
    {return this.show_color_of_second_embed}
    else if(index==2)
    {return this.show_color_of_third_embed}
    else{
    return this.show_color_of_fourth_embed
    }
    },
    hide_colors(index) {
      if (this.show_color) {
        this.show_color = false;
      }
    },
    resetEmbed(index){
    if(index==1)
    {return this.second_new_embed = { fields: [], colors: { hex: "#4f545c" } };}
    else if(index==2)
    {this.third_new_embed = { fields: [], colors: { hex: "#4f545c" } };}
    else if(index==3){
    this.fourth_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    }
    },
    reset_all_Embed() {
    this.new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.second_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.third_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.fourth_new_embed = { fields: [], colors: { hex: "#4f545c" } };

    },
    createEmbed_func(){
    this.CreateEmbed = true;
    this.EditEmbed=false;
    this.new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.second_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.third_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.fourth_new_embed = { fields: [], colors: { hex: "#4f545c" } };

    },
    editEmbed_func(){
    this.EditEmbed=true;
    this.CreateEmbed = false;
    this.new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.second_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.third_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.fourth_new_embed = { fields: [], colors: { hex: "#4f545c" } };
    this.message_content='';
    },
    add_emoji(emoji) {
      return (this.message_content += emoji.native);
    },
    full_json(index) {
      var full_embed = {};
      var just_embed = {};
      var updated_embed=this.get_embed(index)
      if (updated_embed.url) {
        just_embed.url = updated_embed.url;
      }
      if (updated_embed.fields.length !== 0) {
        just_embed.fields = updated_embed.fields;
      }
      if (updated_embed.timestamp) {
        just_embed.timestamp = updated_embed.timestamp;
      }
      if (updated_embed.title) {
        just_embed.title = updated_embed.title;
      }
      if (updated_embed.description) {
        just_embed.description = updated_embed.description;
      }
      if (updated_embed.thumbnail_url) {
        just_embed.thumbnail = { url: updated_embed.thumbnail_url };
      }
      if (updated_embed.image_url) {
        just_embed.image = { url:updated_embed.image_url };
      }
      if (updated_embed.image_url) {
        just_embed.image = { url: updated_embed.image_url };
      }
      if (updated_embed.name_header) {
        just_embed.author = {
          name: updated_embed.name_header,
          url:updated_embed.name_url,
          icon_url: updated_embed.icon_url,
        };
      }
      if (updated_embed.colors) {
        just_embed.color = parseInt(updated_embed.colors.hex.substring(1), 16);
      }
      if (updated_embed.footer_text || updated_embed.footer_icon) {
        just_embed.footer = {
          icon_url: updated_embed.footer_icon,
          text: updated_embed.footer_text,
        };
      }
      if (updated_embed.footer_icon && !updated_embed.footer_text) {
        just_embed.footer = {
          icon_url: updated_embed.footer_icon,
          text: '\u200B'
        };
      }
      full_embed.embed = just_embed;
      return full_embed;
    },
    apply_json(data) {

      this.new_embed = { fields: [], colors: { hex: "#4f545c" } };
      data = data.embed || data;
      this.new_embed  = {
        ...data,
        ...{
          fields: data.fields == undefined ? [] : data.fields,
          first_embed: data.fist_embed == undefined ? [] : data.first_embed,
          second_embed: data.second_embed == undefined ? [] : data.second_embed,
          third_embed: data.third_embed == undefined ? [] : data.third_embed,
          colors: {
            hex:
              data.color == undefined
                ? "#4f545c"
                : "#" + data.color.toString(16).padStart(6, "0"),
          },
          ...{
            name_header:
              data.author == undefined ? undefined : data.author.name,
            icon_url:
              data.author == undefined ? undefined : data.author.icon_url,
            name_url: data.author == undefined ? undefined : data.author.url,
            footer_text:
              data.footer == undefined ? undefined : data.footer.text,
            footer_icon:
              data.footer == undefined ? undefined : data.footer.icon_url,
            thumbnail_url:
              data.thumbnail == undefined ? undefined : data.thumbnail.url,
            image_url: data.image == undefined ? undefined : data.image.url,
          },
        },
      };
      this.$forceUpdate();
    },
    clearDate(index) {
    this.get_embed(index).timestamp = null;
    },
  },
  created: function () {
    this.$Progress.start();
    this.axios
      .get(
        "/api/v1/servers/" +
          this.$route.params.guild_id +
          "/channels?categories=true"
      )
      .then((r) => {
        this.channels = r.data;
        this.$Progress.finish();
      });
  },
  computed: {

    sorted_channels() {
      return this.channels.sort((a, b) => a.position - b.position);
    },
    title_length() {
      return this.new_embed.title == undefined
        ? 0
        : this.new_embed.title.length;
    },
    description_length() {
      return this.new_embed.description == undefined
        ? 0
        : this.new_embed.description.length;
    },
    footer_text_length() {
      return this.new_embed.footer_text == undefined
        ? 0
        : this.new_embed.footer_text.length;
    },
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized",
          },
          channels: [],
        },
      ];
      this.channels.sort((a, b) => a.position - b.position);
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find((g) => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
  },
};
</script>
<style lang="css" scoped>
.preview {
  background: #36393e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  top: 10px;
  float: right;
}
.ecembed {
  background: #36393e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;

  display: inline-block;
  max-width: 520px;
}
.side-colored {
  width: 4px;
  border-radius: 3px 0 0 3px;
  background: #4f545c;
}
.embed {
  border-radius: 0 3px 3px 0;
  background: rgba(47, 49, 54, 1);
  border-color: rgba(46, 48, 54, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 10px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
.embed .card-block {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.embed a {
  color: #0096cf;
}
.embed img.embed-thumb {
  max-height: 80px;
  max-width: 80px;
  border-radius: 3px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
  margin-left: 20px;
}
.embed img.embed-img {
  max-height: 250px;
  max-width: 650px;
  border-radius: 3px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
  margin-bottom: 10px;
}
.embed .embed-footer {
  font-size: 12px;
}
.embed .embed-footer span {
  color: rgba(255, 255, 255, 0.6);
}
.embed .embed-inner .embed-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}
.embed .embed-inner .embed-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}
.embed .embed-inner .embed-author img.embed-author-icon {
  margin-right: 9px;
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 50%;
}
.embed .embed-footer img.embed-author-icon {
  margin-right: 9px;
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 50%;
}
.embed .embed-inner .embed-author .embed-author-name {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #fff !important;
}
.embed .embed-inner .fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  box-lines: miltiple;
  margin-top: -10px;
}
.embed .embed-inner .fields .field {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0;
  box-flex: 1;
  padding-top: 10px;
  max-width: 506px;
  min-width: 100%;
}
.embed .embed-inner .fields .field.inline {
  box-flex: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 130px;
  max-width: 130px;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  word-wrap: break-word;
}
.embed .embed-inner .fields .field .field-name {
  color: #72767d;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: -1rem !important;
}
.embed .embed-inner .fields .field .field-value {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1em;
  white-space: pre-wrap;
  /* margin-top: 6px; */
  word-wrap: break-word;
}
</style>
